<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
    style="position:absolute;top:0"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Solicitação"
          class="px-5 py-3"
        >
          <v-row>
            <v-col cols="3">
              <v-subheader class="display-1 mt-3">
                Código:
              </v-subheader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field
                label="Código"
                outlined
                class="ml-4"
                v-model="code"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="CPF/CNPJ"
                outlined
                class="ml-4"
                v-model="cpf"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
              />
            </v-col>
            <v-col cols="1">
              <v-btn
                color="green"
                @click="searchOrderCode()"
                class="mt-1"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <div v-if="request && !order">
            <hr>
            <v-alert
              color="indigo"
              class="mt-2"
              dark
            >
              Sua solicitação está com o status {{request.status}}.
            </v-alert>
          </div>

          <div v-if="order">
            <hr>
            
            <v-alert
              color="indigo"
              class="mt-2"
              dark
            >
              Sua ordem está com o status {{order.status}}.
            </v-alert>

            <v-subheader class="display-1 mt-3 mb-3">
              <b>Ordem de Serviço</b>
            </v-subheader>

            <div class="ml-n4">
              <v-row>
                <v-col cols="6">
                  <v-textarea
                    filled
                    name="input-7-1"
                    label="Problema relatado"
                    readonly
                    :value="order.reported_problem"
                    hint=""
                    class="ml-5"
                  />
                </v-col>
                <v-col cols="6">
                  <v-textarea
                    filled
                    name="input-7-1"
                    label="Diagnóstico"
                    readonly
                    :value="order.diagnosis"
                    hint=""
                    class="ml-5"
                  />
                </v-col>
              </v-row>
            </div>

            <hr>

            <v-subheader class="display-1 mt-3 mb-3">
              <b>Preço</b>
            </v-subheader>

            <v-row>
              <v-col cols="2">

                <v-text-field
                  label="Preço Serviços"
                  type="number"
                  class="ml-3"
                  readonly
                  :value="order.service_price"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
                <v-text-field
                  label="Frete"
                  readonly
                  type="number"
                  class="ml-3"
                  :value="order.shipping"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">

                <v-text-field
                  label="Desconto"
                  readonly
                  type="number"
                  class="ml-3"
                  :value="order.discount"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
                <v-text-field
                  label="Preço Total"
                  readonly
                  type="number"
                  filled
                  class="ml-3"
                  :value="totalPrice"
                />
              </v-col>
            </v-row>

            <v-row v-if="aprrovalShow">
              <v-col cols="3">
                <v-btn
                  color="green"
                  @click="approve(true)"
                  class="mt-1"
                >
                  APROVAR
                </v-btn>
                <v-btn
                  color="red"
                  @click="approve(false)"
                  class="mt-1"
                >
                  NEGAR
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <v-alert
                  v-if="order.approval"
                  border="left"
                  color="green"
                  dark
                >
                  Você já aprovou esta ordem de serviço,  entre em contat com a nossa equipe para tratar qualquer dúvida.
                </v-alert>
                <v-alert
                  v-else
                  border="left"
                  color="red"
                  dark
                >
                  Você negou esta ordem,  entre em contat com a nossa equipe para tratar qualquer dúvida.
                </v-alert>
              </v-col>
            </v-row>

          </div>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import OrdersService from '@/services/OrdersService'
  import ServiceRequestsService from '@/services/ServiceRequestsService'
  import axios from 'axios'

  export default {
    name: 'Track',

    data: () => ({
      code: "",
      order: null,
      request: null,
      cpf: null,
      aprrovalShow: false,
    }),

    computed: {
      totalPrice () {
        return (parseFloat(this.order.service_price) + parseFloat(this.order.shipping)  -  parseFloat(this.order.discount)).toFixed(2)
      },
    },

    methods: {
      /*async searchCode(){
        this.$toast.info('Procurando...', '',{position:'topRight'})
        await ServiceRequestsService.track(this.code,this.cpf)
        .then((response) => {
          if(response.data.message == "not found"){
            this.searchOrderCode()
          } else {
            this.order = this.request.order
          }
          this.request = response.data.service_request
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao recuperar código.', '',{position:'topRight'})
        })
      },*/
      async searchOrderCode(){
        await OrdersService.track(this.code,this.cpf)
        .then((response) => {
          if(response.data.message == "not found"){
            throw 500
          }
          this.order = response.data.order
          if(this.order.approval == null){
            this.aprrovalShow = true
          }

        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao recuperar código.', '',{position:'topRight'})
        })
      },
      approve(decision){
        OrdersService.approve(decision,this.order.id)
        .then((response) => {
          if(response.data.message == "Error"){
            throw 500
          }
          if(decision){
            this.$toast.success('Ordem Aprovada com Sucesso.', '',{position:'topRight'})
            this.order.approval = true
          } else{
            this.$toast.success('Ordem Negada com Sucesso.', '',{position:'topRight'})
          }

          this.aprrovalShow = false
          
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao aprovar/desaprovar ordem, entre em contato com a Núcleo Digital.', '',{position:'topRight'})
        })
      }

    }
  }
</script>